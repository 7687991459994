import React, { createRef, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../Auth/Provider'
import styles from './Project.module.scss'
import wsClient, { WebSocketClient } from '../WebSocket/WebSocketClient'

type Params = {
  id: string
}

type Project = {
  id: string
  name: string
}

function Project(): React.JSX.Element {
  const { isAuthenticated, getToken, login, logout } = useAuth()
  const params = useParams<Params>()
  const displayRef = createRef<HTMLVideoElement>()
  const audioRef = createRef<HTMLVideoElement>()
  const sourceRef = createRef<HTMLVideoElement>()
  const displayBlockRef = createRef<HTMLDivElement>()

  const [project, setProject] = useState<Project | null>(null)
  const [ws, setWs] = useState<WebSocketClient | null>(null)

  useEffect(() => {
    getToken().then((token) => {
      fetch('/api/v1/projects/' + params.id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => response.json())
        .then((data) => setProject(data))
    })
  }, [getToken])

  function makeVideoDisplayPeerConnection() {
    const peerConnection = new RTCPeerConnection()

    peerConnection.onconnectionstatechange = () => {
      console.log('VideoDisplay: RTC State', peerConnection.connectionState)
    }

    peerConnection.ontrack = (e) => {
      const display = displayRef.current

      if (display === null) {
        return
      }

      display.srcObject = e.streams[0]
      display.muted = true
      display.play().catch(() => {})

      console.log('VideoDisplay: Connected')
    }

    return peerConnection
  }

  function makeAudioDisplayPeerConnection() {
    const peerConnection = new RTCPeerConnection()

    peerConnection.onconnectionstatechange = () => {
      console.log('AudioDisplay: RTC State', peerConnection.connectionState)
    }

    peerConnection.ontrack = (e) => {
      const display = audioRef.current

      if (display === null) {
        return
      }

      display.srcObject = e.streams[0]
      display.muted = true
      display
        .play()
        .then(() => {
          display.muted = false
        })
        .catch(() => {})

      console.log('AudioDisplay: Connected')
    }

    return peerConnection
  }

  function makeVideoSourcePeerConnection(offer: RTCSessionDescriptionInit) {
    const peerConnection = new RTCPeerConnection()

    peerConnection.onconnectionstatechange = () => {
      console.log('VideoSource: RTC State', peerConnection.connectionState)
    }

    peerConnection.setRemoteDescription(offer).then(() => {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: 960 },
            height: { ideal: 540 },
            frameRate: { ideal: 30 },
          },
          audio: false,
        })
        .then((media) => {
          const me = sourceRef.current

          if (me !== null) {
            me.srcObject = media
            me.muted = true
            me.play().catch(() => {})
          }

          media.getTracks().forEach((track) => {
            peerConnection.addTrack(track, media)
          })

          peerConnection.createAnswer().then((answer) => {
            peerConnection.setLocalDescription(answer)
          })
        })
    })

    return peerConnection
  }

  function makeScreenSourcePeerConnection(offer: RTCSessionDescriptionInit) {
    const peerConnection = new RTCPeerConnection()

    peerConnection.onconnectionstatechange = () => {
      console.log('ScreenSource: RTC State', peerConnection.connectionState)
    }

    peerConnection.setRemoteDescription(offer).then(() => {
      navigator.mediaDevices
        .getDisplayMedia({
          video: {
            displaySurface: 'screen',
            frameRate: 15,
            width: { max: 1920 },
          },
          audio: false,
        })
        .then((media) => {
          media.getTracks().forEach((track) => {
            peerConnection.addTrack(track, media)
          })

          peerConnection.createAnswer().then((answer) => {
            peerConnection.setLocalDescription(answer)
          })
        })
    })

    return peerConnection
  }

  function makeAudioSourcePeerConnection(offer: RTCSessionDescriptionInit) {
    const peerConnection = new RTCPeerConnection()

    peerConnection.onconnectionstatechange = () => {
      console.log('AudioSource: RTC State', peerConnection.connectionState)
    }

    peerConnection.setRemoteDescription(offer).then(() => {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            channelCount: 1,
            noiseSuppression: true,
            echoCancellation: false,
            autoGainControl: false,
            sampleRate: { ideal: 48000 },
          },
        })
        .then((media) => {
          media.getTracks().forEach((track) => {
            peerConnection.addTrack(track, media)
          })

          peerConnection.createAnswer().then((answer) => {
            peerConnection.setLocalDescription(answer)
          })
        })
    })

    return peerConnection
  }

  useEffect(() => {
    if (project === null) {
      return
    }

    if (ws === null) {
      setWs(wsClient.connect())
    }

    if (ws === null) {
      return
    }

    ws.onOpen(() => {
      ws.send({
        type: 'bind-to-project',
        project: project.id,
        data: {},
      })
      ws.send({
        type: 'start',
        data: {
          project: project.id,
        },
      })
    })

    ws.onMessage(async (e: MessageEvent) => {
      const message = JSON.parse(e.data) as {
        from: string
        to?: string
        project?: string
        type: string
        data: object
      }

      if (message.type === 'project-started') {
        ws.send({
          type: 'video-source',
          project: project.id,
          data: {},
        })
      }

      if (message.type === 'video-source-offer') {
        const data = message.data as {
          sdp: string
        }

        const peerConnection = makeVideoSourcePeerConnection({
          type: 'offer',
          sdp: data.sdp,
        })

        peerConnection.onicegatheringstatechange = () => {
          if (peerConnection.iceGatheringState === 'complete') {
            const answer = peerConnection.localDescription
            if (answer !== null) {
              ws.send({
                type: 'video-source-answer',
                project: message.project,
                data: {
                  sdp: answer.sdp,
                },
              })
            }
          }
        }

        peerConnection.onicecandidate = (event) => {
          if (event.candidate !== null) {
            ws.send({
              type: 'video-source-candidate',
              project: message.project,
              data: {
                candidate: event.candidate,
              },
            })
          }
        }
      }
    })

    ws.onMessage(async (e: MessageEvent) => {
      const message = JSON.parse(e.data) as {
        from: string
        to?: string
        project?: string
        type: string
        data: object
      }

      if (message.type === 'project-started') {
        ws.send({
          type: 'audio-source',
          project: project.id,
          data: {},
        })
      }

      if (message.type === 'audio-source-offer') {
        const data = message.data as {
          sdp: string
        }

        const peerConnection = makeAudioSourcePeerConnection({
          type: 'offer',
          sdp: data.sdp,
        })

        peerConnection.onicegatheringstatechange = () => {
          if (peerConnection.iceGatheringState === 'complete') {
            const answer = peerConnection.localDescription
            if (answer !== null) {
              ws.send({
                type: 'audio-source-answer',
                project: message.project,
                data: {
                  sdp: answer.sdp,
                },
              })
            }
          }
        }

        peerConnection.onicecandidate = (event) => {
          if (event.candidate !== null) {
            ws.send({
              type: 'audio-source-candidate',
              project: message.project,
              data: {
                candidate: event.candidate,
              },
            })
          }
        }
      }
    })

    ws.onMessage(async (e: MessageEvent) => {
      const message = JSON.parse(e.data) as {
        from: string
        to?: string
        project?: string
        type: string
        data: object
      }

      if (message.type === 'screen-source-offer') {
        const data = message.data as {
          sdp: string
        }

        const peerConnection = makeScreenSourcePeerConnection({
          type: 'offer',
          sdp: data.sdp,
        })

        peerConnection.onicegatheringstatechange = () => {
          if (peerConnection.iceGatheringState === 'complete') {
            const answer = peerConnection.localDescription
            if (answer !== null) {
              ws.send({
                type: 'screen-source-answer',
                project: message.project,
                data: {
                  sdp: answer.sdp,
                },
              })
            }
          }
        }

        peerConnection.onicecandidate = (event) => {
          if (event.candidate !== null) {
            ws.send({
              type: 'screen-source-candidate',
              project: message.project,
              data: {
                candidate: event.candidate,
              },
            })
          }
        }
      }
    })

    const display = displayRef.current

    if (display === null) {
      return
    }

    const videoDisplayPeerConnection = makeVideoDisplayPeerConnection()

    ws.onMessage(async (e: MessageEvent) => {
      const message = JSON.parse(e.data) as {
        from: string
        to?: string
        project?: string
        type: string
        data: object
      }

      if (message.type === 'project-started') {
        ws.send({
          type: 'video-display',
          project: project.id,
          data: {},
        })
      }

      if (message.type === 'video-display-offer') {
        const data = message.data as {
          sdp: string
        }

        videoDisplayPeerConnection.onicecandidate = (event) => {
          if (event.candidate !== null) {
            ws.send({
              type: 'avideo-display-candidate',
              project: message.project,
              data: {
                candidate: event.candidate,
              },
            })
          }
        }

        await videoDisplayPeerConnection.setRemoteDescription({
          type: 'offer',
          sdp: data.sdp,
        })

        const answer = await videoDisplayPeerConnection.createAnswer()
        await videoDisplayPeerConnection.setLocalDescription(answer)

        ws.send({
          type: 'video-display-answer',
          project: message.project,
          data: {
            sdp: answer.sdp,
          },
        })
      }
    })

    const audioDisplayPeerConnection = makeAudioDisplayPeerConnection()

    ws.onMessage(async (e: MessageEvent) => {
      const message = JSON.parse(e.data) as {
        from: string
        to?: string
        project?: string
        type: string
        data: object
      }

      if (message.type === 'project-started') {
        ws.send({
          type: 'audio-display',
          project: project.id,
          data: {},
        })
      }

      if (message.type === 'audio-display-offer') {
        const data = message.data as {
          sdp: string
        }

        await audioDisplayPeerConnection.setRemoteDescription({
          type: 'offer',
          sdp: data.sdp,
        })

        const answer = await audioDisplayPeerConnection.createAnswer()
        await audioDisplayPeerConnection.setLocalDescription(answer)

        ws.send({
          type: 'audio-display-answer',
          project: message.project,
          data: {
            sdp: answer.sdp,
          },
        })
      }
    })

    return () => ws.close()
  }, [project, ws])

  const shareScreen = () => {
    if (project === null) {
      return
    }

    if (ws === null) {
      return
    }

    ws.send({
      type: 'screen-source',
      project: project.id,
      data: {},
    })
  }

  const fullScreen = () => {
    const div = displayBlockRef.current

    if (!div) {
      return
    }

    if ('requestFullscreen' in div) {
      if (!document.fullscreenElement) {
        div.requestFullscreen().catch(() => {})
      } else {
        document.exitFullscreen()
      }
    }
  }

  if (!isAuthenticated) {
    login()
    return <></>
  }

  return (
    <>
      <div className="wrap">
        <div className="header-bar">
          <div className="container">
            <div className="logo">
              <Link to="/">veveo.ru</Link>
            </div>
            {project !== null ? <div className="name">{project.name}</div> : null}
            <nav>
              <a
                data-testid="logout-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  logout()
                }}
              >
                Выход
              </a>
            </nav>
          </div>
        </div>
        <div className="container">
          {project !== null ? (
            <div data-testid="project">
              <div className={styles.audio}>
                <video ref={audioRef} autoPlay controls />
              </div>
              <div ref={displayBlockRef} className={styles.screen} onDoubleClick={() => fullScreen()}>
                <div className="responsive responsive-16x9">
                  <video ref={displayRef} onContextMenu={(e) => e.preventDefault()} autoPlay />
                </div>
              </div>
              <div className={styles.controls}>
                <button type="button" onClick={() => shareScreen()}>
                  Экран
                </button>
              </div>
              <div className={styles.sources}>
                <div className={styles.source}>
                  <div className="responsive responsive-16x9">
                    <video ref={sourceRef} autoPlay />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div>&copy; veveo.ru</div>
        </div>
      </div>
    </>
  )
}

export default Project
